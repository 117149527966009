import { RouteConfig } from "vue-router";

export default [
  {
    path: "budget-module",
    name: "panel.event.view.budgetModule.view",
    component: () =>
      import(
        /* webpackChunkName: "event.view.budgetModule.view" */ "@/views/Panel/Event/View/Modules/BudgetModule/View.vue"
      ),
  },
] as RouteConfig[];
