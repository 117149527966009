import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'partner',
    name: 'panel.partner.list',
    component: () => import(/* webpackChunkName: "partner.list" */ '@/views/Panel/GlobalTools/Partner/List.vue')
  },
  {
    path: 'partner/add',
    name: 'panel.partner.add',
    component: () => import(/* webpackChunkName: "partner.add" */ '@/views/Panel/GlobalTools/Partner/Add.vue')
  },
  {
    path: 'partner/:id',
    name: 'panel.partner.edit',
    component: () => import(/* webpackChunkName: "partner.edit" */ '@/views/Panel/GlobalTools/Partner/Edit.vue')
  }
] as RouteConfig[]