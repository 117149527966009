import i18n from '@/i18n'
import vuetify from '@/plugins/vuetify'
import { State } from '@/store/state'
import { ActionTree } from 'vuex'
import { LanguageState } from './state'

export default {
  setLanguage: ({ commit, dispatch }, language: 'pl' | 'en') => {
    commit('SET_LANGUAGE', language)
    dispatch('updateLanguage')
  },
  updateLanguage: ({ state }) => {
    vuetify.framework.lang.current = state.current
    i18n.locale = state.current
  }
} as ActionTree<LanguageState, State>
