import { RouteConfig } from "vue-router";

export default [
  {
    path: "survey-module",
    name: "panel.event.view.surveyModule.list",
    component: () =>
      import(
        /* webpackChunkName: "event.view.surveyModule.list" */ "@/views/Panel/Event/View/Modules/SurveyModule/List.vue"
      ),
  },
  {
    path: "survey-module/add",
    name: "panel.event.view.surveyModule.add",
    component: () =>
      import(
        /* webpackChunkName: "event.view.surveyModule.add" */ "@/views/Panel/Event/View/Modules/SurveyModule/Add.vue"
      ),
  },
  {
    path: "survey-module/edit/:smid",
    name: "panel.event.view.surveyModule.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.view.surveyModule.edit" */ "@/views/Panel/Event/View/Modules/SurveyModule/Edit.vue"
      ),
  },
  {
    path: "survey-module/question/:smid",
    name: "panel.event.view.surveyModule.question",
    component: () =>
      import(
        /* webpackChunkName: "event.view.surveyModule.question.add" */ "@/views/Panel/Event/View/Modules/SurveyModule/Question.vue"
      ),
  },
  {
    path: "survey-module/question/edit/:smid",
    name: "panel.event.view.surveyModule.question.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.view.surveyModule.question.edit" */ "@/views/Panel/Event/View/Modules/SurveyModule/EditQuestion.vue"
      ),
  },
  {
    path: "surveys/list",
    name: "panel.event.view.surveys.list",
    component: () =>
      import(
        /* webpackChunkName: "event.view.surveys.list" */ "@/views/Panel/Event/View/Surveys/List.vue"
      ),
  },
] as RouteConfig[];
