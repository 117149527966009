import { State } from "@/store/state";
import { GetterTree } from "vuex";
import { UserState } from "./state";

const getObjectElementByString = (o: any, s: string) => {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  const a = s.split(".");

  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];

    if (k in o) o = o[k];
    else return;
  }
  return o;
};

export default {
  isLoggedIn: (state) => (state.auth.token ? true : false),
  getToken: (state) => state.auth.token || false,
  getData: (state) => state.data || false,
  hasAccessTo: (state) => (permission: string) => {
    const roles = ["security", "committee", "coordinator", "employee", "admin"];

    if (roles.includes(permission))
      return (
        roles.indexOf(state.data ? state.data.role : "guest") >=
        roles.indexOf(permission)
      );

    if (state.data?.authGroup && state.data.role === "coordinator") {
      return getObjectElementByString(state.data.authGroup, permission);
    }

    return true;
  },
} as GetterTree<UserState, State>;
