import { RouteConfig } from "vue-router";

export default [
  {
    path: "additional-service",
    name: "panel.event.view.additionalService.list",
    component: () =>
      import(
        /* webpackChunkName: "event.additionalService.list" */ "@/views/Panel/Event/View/Modules/AdditionalService/List.vue"
      ),
  },
  {
    path: "additional-service/add",
    name: "panel.event.view.additionalService.add",
    component: () =>
      import(
        /* webpackChunkName: "event.additionalService.add" */ "@/views/Panel/Event/View/Modules/AdditionalService/Add.vue"
      ),
  },
  {
    path: "additional-service/edit/:asid",
    name: "panel.event.view.additionalService.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.additionalService.edit" */ "@/views/Panel/Event/View/Modules/AdditionalService/Edit.vue"
      ),
  },
  {
    path: "additional-service/group/add",
    name: "panel.event.view.additionalService.group.add",
    component: () =>
      import(
        /* webpackChunkName: "event.additionalService.group.add" */ "@/views/Panel/Event/View/Modules/AdditionalService/AddGroup.vue"
      ),
  },
  {
    path: "additional-service/group/edit/:asgid",
    name: "panel.event.view.additionalService.group.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.additionalService.group.edit" */ "@/views/Panel/Event/View/Modules/AdditionalService/EditGroup.vue"
      ),
  },
] as RouteConfig[];
