import { RouteConfig } from "vue-router";

export default [
  {
    path: "accommodation-module/config",
    name: "panel.event.view.accommodationModule.config",
    component: () =>
      import(
        /* webpackChunkName: "event.accommodationModule.config" */ "@/views/Panel/Event/View/Modules/AccommodationModule/Config.vue"
      ),
  },
  {
    path: "accommodation-module",
    name: "panel.event.view.accommodationModule.list",
    component: () =>
      import(
        /* webpackChunkName: "event.accommodationModule.list" */ "@/views/Panel/Event/View/Modules/AccommodationModule/List.vue"
      ),
  },
  {
    path: "accommodation-module/add-hotel",
    name: "panel.event.view.accommodationModule.addHotel",
    component: () =>
      import(
        /* webpackChunkName: "event.accommodationModule.addHotel" */ "@/views/Panel/Event/View/Modules/AccommodationModule/AddHotel.vue"
      ),
  },
  {
    path: "accommodation-module/edit-hotel/:ahid",
    name: "panel.event.view.accommodationModule.editHotel",
    component: () =>
      import(
        /* webpackChunkName: "event.accommodationModule.editHotel" */ "@/views/Panel/Event/View/Modules/AccommodationModule/EditHotel.vue"
      ),
  },
  {
    path: "accommodation-module/add-room",
    name: "panel.event.view.accommodationModule.addRoom",
    component: () =>
      import(
        /* webpackChunkName: "event.accommodationModule.addRoom" */ "@/views/Panel/Event/View/Modules/AccommodationModule/AddRoom.vue"
      ),
  },
  {
    path: "accommodation-module/editRoom/:arid",
    name: "panel.event.view.accommodationModule.editRoom",
    component: () =>
      import(
        /* webpackChunkName: "event.accommodationModule.editRoom" */ "@/views/Panel/Event/View/Modules/AccommodationModule/EditRoom.vue"
      ),
  },
] as RouteConfig[];
