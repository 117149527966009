import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import pl from 'vuetify/src/locale/pl'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { pl },
    current: 'pl'
  },
  theme: {
    themes: {
      light: {
        primary: '#4CAF50',
        secondary: '#E1E1E1',
        dark: '#231f20',
        red: '#D8241D',
      }
    }
  }
})
