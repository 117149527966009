import { RouteConfig } from "vue-router";

export default [
  {
    path: "participant",
    name: "panel.event.view.participant.list",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "event.participant.list" */ "@/views/Panel/Event/View/Participant/List.vue"
      ),
  },
  {
    path: "participant/add",
    name: "panel.event.view.participant.add",
    component: () =>
      import(
        /* webpackChunkName: "event.participant.add" */ "@/views/Panel/Event/View/Participant/Add.vue"
      ),
  },
  {
    path: "participant/edit/:ptid",
    name: "panel.event.view.participant.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.participant.edit" */ "@/views/Panel/Event/View/Participant/Edit.vue"
      ),
  },
  {
    path: "participant/import",
    name: "panel.event.view.participant.import",
    component: () =>
      import(
        /* webpackChunkName: "event.participant.import" */ "@/views/Panel/Event/View/Participant/Import.vue"
      ),
  },
  {
    path: "participant/messages/:ptid",
    name: "panel.event.view.participant.messages",
    component: () =>
      import(
        /* webpackChunkName: "event.participant.messages" */ "@/views/Panel/Event/View/Participant/Messages.vue"
      ),
  },
  {
    path: "participant/search",
    name: "panel.event.view.participant.search",
    props: { fee: "", room: "", service: "", group: "" },
    component: () =>
      import(
        /* webpackChunkName: "event.participant.search" */ "@/views/Panel/Event/View/Participant/Search.vue"
      ),
  },
] as RouteConfig[];
