export interface AuthData {
  token?: string;
}

export interface UserPermissions {
  eventPanel: {
    alerts: boolean;
  };
  participants: {
    participantsList: boolean;
    participantsManagement: boolean;
    participantsInvoice: boolean;
  };
  lecturers: {
    lecturersList: boolean;
    lecturersManagement: boolean;
  };
  companies: {
    companiesList: boolean;
    companiesManagement: boolean;
  };
  abstracts: {
    abstractsLists: boolean;
    abstractsManagement: boolean;
  };
  posterSession: {
    posterSessionList: boolean;
  };
  surveys: {
    surveysList: boolean;
  };
  accessControl: {
    accessControlView: boolean;
    accessControlManagement: boolean;
  };
  print: {
    printList: boolean;
    printManagement: boolean;
  };
  reports: {
    reportsManagement: boolean;
  };
  info: {
    view: boolean;
  };
  eventConfig: {
    discounts: boolean;
    eventData: boolean;
    messages: boolean;
    participantGroups: boolean;
  };
  eventModules: {
    registrationModule: {
      moduleData: boolean;
      moduleDegree: boolean;
      moduleFee: boolean;
    };
    accomodationModule: {
      moduleData: boolean;
      moduleHotels: boolean;
    };
    additionalServicesModule: {
      moduleData: boolean;
      moduleForms: boolean;
      moduleServices: boolean;
    };
    abstractsModule: {
      moduleData: boolean;
      modulePublications: boolean;
      moduleSessions: boolean;
    };
    partnersModule: {
      view: boolean;
    };
    eventProgramModule: {
      moduleLocalization: boolean;
      modulePartner: boolean;
    };
    posterSessionModule: {
      view: boolean;
    };
    surveysModule: {
      view: boolean;
    };
    companiesModule: {
      moduleAdditionalServices: boolean;
      moduleCodes: boolean;
      moduleData: boolean;
      moduleFees: boolean;
      moduleHotels: boolean;
    };
    receptionModule: {
      moduleCertificates: boolean;
      moduleIdentities: boolean;
      moduleKiosks: boolean;
      moduleTerminals: boolean;
    };
    autoMessagesModule: {
      view: boolean;
    };
    siemens: {
      reports: boolean;
    };
  };
}

export interface UserData {
  role: "employee" | "admin" | "coordinator" | "committee" | "security";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  authGroup?: UserPermissions;
}

export interface UserState {
  auth: AuthData;
  data?: UserData;
}

export const state: UserState = {
  auth: { token: undefined },
  data: undefined,
};
