import { RouteConfig } from "vue-router";

export default [
  {
    path: "registration-module",
    name: "panel.event.view.registrationModule.view",
    component: () =>
      import(
        /* webpackChunkName: "event.registrationModule.view" */ "@/views/Panel/Event/View/Modules/RegistrationModule/View.vue"
      ),
  },
  {
    path: "registration-module/fees",
    name: "panel.event.view.registrationModule.fees.list",
    component: () =>
      import(
        /* webpackChunkName: "event.registrationModule.fees.list" */ "@/views/Panel/Event/View/Modules/RegistrationModule/Fees/List.vue"
      ),
  },
  {
    path: "registration-module/fees/add",
    name: "panel.event.view.registrationModule.fees.add",
    component: () =>
      import(
        /* webpackChunkName: "event.registrationModule.fees.add" */ "@/views/Panel/Event/View/Modules/RegistrationModule/Fees/Add.vue"
      ),
  },
  {
    path: "registration-module/fees/edit/:rfid",
    name: "panel.event.view.registrationModule.fees.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.registrationModule.fees.edit" */ "@/views/Panel/Event/View/Modules/RegistrationModule/Fees/Edit.vue"
      ),
  },
  {
    path: "registration-module/fee-group/add",
    name: "panel.event.view.registrationModule.feeGroup.add",
    component: () =>
      import(
        /* webpackChunkName: "event.view.registrationModule.feeGroup.add" */ "@/views/Panel/Event/View/Modules/RegistrationModule/Fees/AddGroup.vue"
      ),
  },
  {
    path: "registration-module/fee-group/edit/:rfgid",
    name: "panel.event.view.registrationModule.feeGroup.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.view.registrationModule.feeGroup.edit" */ "@/views/Panel/Event/View/Modules/RegistrationModule/Fees/EditGroup.vue"
      ),
  },
  {
    path: "registration-module/paymentMethod",
    name: "panel.event.view.registrationModule.paymentMethod.view",
    component: () =>
      import(
        /* webpackChunkName: "event.registrationModule.paymentMethod.view" */ "@/views/Panel/Event/View/Modules/RegistrationModule/PaymentMethod/View.vue"
      ),
  },
  {
    path: "registration-module/config",
    name: "panel.event.view.registrationModule.config",
    component: () =>
      import(
        /* webpackChunkName: "event.registrationModule.config" */ "@/views/Panel/Event/View/Modules/RegistrationModule/Config.vue"
      ),
  },
] as RouteConfig[];
