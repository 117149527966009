import { RouteConfig } from "vue-router";

export default [
  {
    path: "mass-printing",
    name: "panel.event.view.massPrinting",
    component: () =>
      import(
        /* webpackChunkName: "event.massPrinting" */ "@/views/Panel/Event/View/MassPrinting/View.vue"
      ),
  },
] as RouteConfig[];
