import { State } from '@/store/state'
import { GetterTree } from 'vuex'
import { ApiState } from './state'

import axios from 'axios'

export default {
  getInstance: (state, getters, rootState, rootGetters) => axios.create({
    baseURL: state.baseURL,
    headers: {
      'Authorization': `Bearer ${rootGetters['user/getToken']}`
    }
  }),
} as GetterTree<ApiState, State>