import { RouteConfig } from "vue-router";

export default [
  {
    path: "",
    name: "panel.event.view.info",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "event.view.info" */ "@/views/Panel/Event/View/Info.vue"
      ),
  },
  {
    path: "summary-info",
    name: "panel.event.view.summaryInfo",
    component: () =>
      import(
        /* webpackChunkName: "event.view.summaryInfo" */ "@/views/Panel/Event/View/SummaryInfo/SummaryInfo.vue"
      ),
  },
] as RouteConfig[];
