import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'specialization',
    name: 'panel.specialization.list',
    component: () => import(/* webpackChunkName: "specialization.list" */ '@/views/Panel/GlobalTools/Specialization/List.vue')
  },
  {
    path: 'specialization/add',
    name: 'panel.specialization.add',
    component: () => import(/* webpackChunkName: "specialization.add" */ '@/views/Panel/GlobalTools/Specialization/Add.vue')
  }
] as RouteConfig[]