import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'user',
    name: 'panel.user.list',
    component: () => import(/* webpackChunkName: "user.list" */ '@/views/Panel/User/List.vue')
  },
  {
    path: 'user/add',
    name: 'panel.user.add',
    component: () => import(/* webpackChunkName: "user.add" */ '@/views/Panel/User/Add.vue')
  },
  {
    path: 'user/:id',
    name: 'panel.user.edit',
    component: () => import(/* webpackChunkName: "user.edit" */ '@/views/Panel/User/Edit.vue')
  },
  {
    path: 'user/group',
    name: 'panel.user.group',
    component: () => import(/* webpackChunkName: "user.group" */ '@/views/Panel/User/Group.vue')
  },
  {
    path: 'user/group-add',
    name: 'panel.user.group.add',
    component: () => import(/* webpackChunkName: "user.group.add" */ '@/views/Panel/User/AddGroup.vue')
  },
  {
    path: 'user/group-edit/:id',
    name: 'panel.user.group.edit',
    component: () => import(/* webpackChunkName: "user.group.edit" */ '@/views/Panel/User/EditGroup.vue')
  }
] as RouteConfig[]