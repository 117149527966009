import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";

import "./plugins/vuetify-snackbar-queue";
import "./plugins/composition-api";
import "./plugins/moment";
import "./plugins/vue-the-mask";
import "./plugins/vue-country-flag";
import "./plugins/tinymce";
import "./plugins/v-uppercase";
import "./plugins/v-slug";
import "./plugins/iconify";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
