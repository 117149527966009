import { RouteConfig } from "vue-router";

export default [
  {
    path: 'auto-messages',
    name: 'panel.event.view.autoMessages.list',
    component: () => import(/* webpackChunkName: "event.view.autoMessages.list" */ '@/views/Panel/Event/View/Modules/AutoMessages/List.vue')
  },
  {
    path: 'auto-messages/add',
    name: 'panel.event.view.autoMessages.add',
    component: () => import(/* webpackChunkName: "event.view.autoMessages.add" */ '@/views/Panel/Event/View/Modules/AutoMessages/Add.vue')
  },
  {
    path: 'auto-messages/edit/:amid',
    name: 'panel.event.view.autoMessages.edit',
    component: () => import(/* webpackChunkName: "event.view.autoMessages.edit" */ '@/views/Panel/Event/View/Modules/AutoMessages/Edit.vue')
  }
] as RouteConfig[]