import { RouteConfig } from "vue-router";

export default [
  {
    path: 'access-control',
    name: 'panel.event.view.accessControl.list',
    component: () => import(/* webpackChunkName: "event.view.accessControl.list" */ '@/views/Panel/Event/View/AccessControl/List.vue')
  },
  {
    path: 'access-control/add',
    name: 'panel.event.view.accessControl.add',
    component: () => import(/* webpackChunkName: "event.view.accessControl.add" */ '@/views/Panel/Event/View/AccessControl/Add.vue')
  },
  {
    path: 'access-control/edit/:acid',
    name: 'panel.event.view.accessControl.edit',
    component: () => import(/* webpackChunkName: "event.view.accessControl.edit" */ '@/views/Panel/Event/View/AccessControl/Edit.vue')
  },
  {
    path: 'access-control/attendance-list',
    name: 'panel.event.view.accessControl.attendanceList.list',
    component: () => import(/* webpackChunkName: "event.view.accessControl.attendanceList.list" */ '@/views/Panel/Event/View/AccessControl/AttendanceList.vue')
  },
  {
    path: 'access-control/single-attendance-list/:said',
    name: 'panel.event.view.accessControl.singleAttendanceList.list',
    component: () => import(/* webpackChunkName: "event.view.accessControl.singleAttendanceList.list" */ '@/views/Panel/Event/View/AccessControl/SinglePresenceList.vue')
  },
  {
    path: 'access-control/config',
    name: 'panel.event.view.accessControl.config',
    component: () => import(/* webpackChunkName: "event.view.accessControl.config" */ '@/views/Panel/Event/View/AccessControl/Config.vue')
  }
] as RouteConfig[]