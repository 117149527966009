import store from '@/store'
import { RouteConfig } from 'vue-router'

export default {
  path: '/auth',
  name: 'auth',
  component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth.vue'),
  redirect: { name: 'auth.login' },
  beforeEnter(to, from, next) {
    if (store.getters['user/isLoggedIn']) next({ name: 'panel' })
    next()
  },
  children: [
    {
      path: '',
      name: 'auth.login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Auth/Login.vue')
    },
    {
      path: 'forgot-password',
      name: 'auth.forgot-password',
      component: () => import(/* webpackChunkName: "forgot-password" */ '@/views/Auth/ForgotPassword.vue')
    }
  ]
} as RouteConfig