import store from '@/store'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import auth from './auth'
import panel from './panel'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [auth, panel]

// const routes: Array<RouteConfig> = [
//   {
//     path: '/auth',
//     name: 'auth',
//     component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
//     redirect: { name: 'auth.login' },
//     beforeEnter(to, from, next) {
//       if (store.getters['user/isLoggedIn']) next({ name: 'panel' })
//       next()
//     },
//     children: [
//       {
//         path: '',
//         name: 'auth.login',
//         component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue')
//       },
//       {
//         path: 'forgot-password',
//         name: 'auth.forgot-password',
//         component: () => import(/* webpackChunkName: "forgot-password" */ '../views/Auth/ForgotPassword.vue')
//       }
//     ]
//   },
//   {
//     path: '/',
//     name: 'panel',
//     component: () => import(/* webpackChunkName: "panel" */ '../views/Panel.vue'),
//     redirect: { name: 'panel.dashboard' },
//     beforeEnter(to, from, next) {
//       if (!store.getters['user/isLoggedIn']) next({ name: 'auth', query: { next: to.fullPath } })
//       next()
//     },
//     children: [
//       {
//         path: '',
//         name: 'panel.dashboard',
//         component: () => import(/* webpackChunkName: "dashboard" */ '../views/Panel/Dashboard.vue')
//       }
//     ]
//   },
//   {
//     path: '*',
//     name: 'error404',
//     redirect: { name: 'panel' }
//   }
// ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('user/update')
  next()
})

export default router