import { RouteConfig } from "vue-router";

export default [
  {
    path: "certificate",
    name: "panel.event.view.receptionConfig.certificate.list",
    component: () =>
      import(
        /* webpackChunkName: "event.receptionConfig.certificate.list" */ "@/views/Panel/Event/View/Modules/ReceptionConfig/Certificates/List.vue"
      ),
  },
  {
    path: "certificate/add",
    name: "panel.event.view.receptionConfig.certificate.add",
    component: () =>
      import(
        /* webpackChunkName: "event.receptionConfig.certificate.add" */ "@/views/Panel/Event/View/Modules/ReceptionConfig/Certificates/Add.vue"
      ),
  },
  {
    path: "certificate/edit/:cid",
    name: "panel.event.view.receptionConfig.certificate.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.receptionConfig.certificate.edit" */ "@/views/Panel/Event/View/Modules/ReceptionConfig/Certificates/Edit.vue"
      ),
  },
  {
    path: "identifier",
    name: "panel.event.view.receptionConfig.identifier.list",
    component: () =>
      import(
        /* webpackChunkName: "event.receptionConfig.identifier" */ "@/views/Panel/Event/View/Modules/ReceptionConfig/Identifiers/List.vue"
      ),
  },
  {
    path: "identifier/add",
    name: "panel.event.view.receptionConfig.identifier.add",
    component: () =>
      import(
        /* webpackChunkName: "event.receptionConfig.identifier.add" */ "@/views/Panel/Event/View/Modules/ReceptionConfig/Identifiers/Add.vue"
      ),
  },
  {
    path: "identifier/edit/:irid",
    name: "panel.event.view.receptionConfig.identifier.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.receptionConfig.identifier.edit" */ "@/views/Panel/Event/View/Modules/ReceptionConfig/Identifiers/Edit.vue"
      ),
  },
  {
    path: "plate",
    name: "panel.event.view.receptionConfig.plate.list",
    component: () =>
      import(
        /* webpackChunkName: "event.receptionConfig.plate" */ "@/views/Panel/Event/View/Modules/ReceptionConfig/Plates/List.vue"
      ),
  },
  {
    path: "plate/add",
    name: "panel.event.view.receptionConfig.plate.add",
    component: () =>
      import(
        /* webpackChunkName: "event.receptionConfig.plate.add" */ "@/views/Panel/Event/View/Modules/ReceptionConfig/Plates/Add.vue"
      ),
  },
  {
    path: "plate/edit/:pid",
    name: "panel.event.view.receptionConfig.plate.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.receptionConfig.plate.edit" */ "@/views/Panel/Event/View/Modules/ReceptionConfig/Plates/Edit.vue"
      ),
  },
] as RouteConfig[];
