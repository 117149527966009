import { RouteConfig } from "vue-router";

export default [
  {
    path: "company",
    name: "panel.event.view.company.list",
    component: () =>
      import(
        /* webpackChunkName: "event.company.list" */ "@/views/Panel/Event/View/Company/List.vue"
      ),
  },
  {
    path: "company/add",
    name: "panel.event.view.company.add",
    component: () =>
      import(
        /* webpackChunkName: "event.company.add" */ "@/views/Panel/Event/View/Company/Add.vue"
      ),
  },
  {
    path: "company/edit/:cid",
    name: "panel.event.view.company.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.company.edit" */ "@/views/Panel/Event/View/Company/Edit.vue"
      ),
  },
  {
    path: "company/config",
    name: "panel.event.view.company.config",
    component: () =>
      import(
        /* webpackChunkName: "event.company.config" */ "@/views/Panel/Event/View/Company/Config.vue"
      ),
  },
] as RouteConfig[];
