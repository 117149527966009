import { RouteConfig } from "vue-router";

export default [
  {
    path: "poster-session",
    name: "panel.event.view.posterSession.list",
    component: () =>
      import(
        /* webpackChunkName: "event.posterSession.list" */ "@/views/Panel/Event/View/PosterSession/List.vue"
      ),
  },
  {
    path: "poster-session/config",
    name: "panel.event.view.posterSession.config",
    component: () =>
      import(
        /* webpackChunkName: "event.posterSession.config" */ "@/views/Panel/Event/View/PosterSession/Config.vue"
      ),
  },
] as RouteConfig[];
