import { RouteConfig } from "vue-router";

export default [
  {
    path: "mailAddress",
    name: "panel.mailAddress.list",
    component: () =>
      import(
        /* webpackChunkName: "mailAddress.list" */ "@/views/Panel/GlobalTools/MailAddresses/List.vue"
      ),
  },
  {
    path: "mailAddress/add",
    name: "panel.mailAddress.add",
    component: () =>
      import(
        /* webpackChunkName: "mailAddress.add" */ "@/views/Panel/GlobalTools/MailAddresses/Add.vue"
      ),
  },
  {
    path: "mailAddress/:id",
    name: "panel.mailAddress.edit",
    component: () =>
      import(
        /* webpackChunkName: "mailAddress.edit" */ "@/views/Panel/GlobalTools/MailAddresses/Edit.vue"
      ),
  },
] as RouteConfig[];
