import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'registration-header',
    name: 'panel.registrationHeader.list',
    component: () => import(/* webpackChunkName: "registrationHeader.list" */ '@/views/Panel/GlobalTools/RegistrationHeader/List.vue')
  },
  {
    path: 'registration-header/add',
    name: 'panel.registrationHeader.add',
    component: () => import(/* webpackChunkName: "registrationHeader.add" */ '@/views/Panel/GlobalTools/RegistrationHeader/Add.vue')
  },
  {
    path: 'registration-header/:id',
    name: 'panel.registrationHeader.edit',
    component: () => import(/* webpackChunkName: "registrationHeader.edit" */ '@/views/Panel/GlobalTools/RegistrationHeader/Edit.vue')
  }
] as RouteConfig[]