import { RouteConfig } from 'vue-router';

export default [{
  path: '',
  name: 'panel.dashboard',
  component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Panel/Dashboard.vue')
},
{
  path: '/search',
  name: 'panel.search',
  component: () => import(/* webpackChunkName: "search" */ '@/views/Panel/Search.vue')
}
] as RouteConfig[]