import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'location',
    name: 'panel.location.list',
    component: () => import(/* webpackChunkName: "location.list" */ '@/views/Panel/GlobalTools/Location/List.vue')
  },
  {
    path: 'location/add',
    name: 'panel.location.add',
    component: () => import(/* webpackChunkName: "location.add" */ '@/views/Panel/GlobalTools/Location/Add.vue')
  },
  {
    path: 'location/:id',
    name: 'panel.location.edit',
    component: () => import(/* webpackChunkName: "location.edit" */ '@/views/Panel/GlobalTools/Location/Edit.vue')
  }
] as RouteConfig[]