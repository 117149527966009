import { RouteConfig } from "vue-router";

export default [
  {
    path: "additional-config",
    name: "panel.event.view.additionalConfig.config",
    component: () =>
      import(
        /* webpackChunkName: "event.additionalConfig.config" */ "@/views/Panel/Event/View/EventConfig/AdditionalConfig/Config.vue"
      ),
  },
] as RouteConfig[];
