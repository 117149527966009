import Vue from 'vue'
import slugify from 'slugify'

Vue.directive('slug', {
  bind (el: any, _, vnode) {
    el.addEventListener('input', (e: any) => {
      e.target.value = slugify(e.target.value.toLowerCase())
      vnode?.componentInstance?.$emit('input', slugify(e.target.value.toLowerCase()))
    })
  }
})
