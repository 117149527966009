import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'message',
    name: 'panel.message.list',
    component: () => import(/* webpackChunkName: "message.list" */ '@/views/Panel/GlobalTools/Message/List.vue')
  },
  {
    path: 'message/add',
    name: 'panel.message.add',
    component: () => import(/* webpackChunkName: "message.add" */ '@/views/Panel/GlobalTools/Message/Add.vue')
  },
  {
    path: 'message/:id',
    name: 'panel.message.edit',
    component: () => import(/* webpackChunkName: "message.edit" */ '@/views/Panel/GlobalTools/Message/Edit.vue')
  }
] as RouteConfig[]