import { RouteConfig } from "vue-router";

export default [
  {
    path: "abstract",
    name: "panel.event.view.abstract.list",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.list" */ "@/views/Panel/Event/View/Abstract/List.vue"
      ),
  },
  {
    path: "abstract/add",
    name: "panel.event.view.abstract.add",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.add" */ "@/views/Panel/Event/View/Abstract/Add.vue"
      ),
  },
  {
    path: "abstract/edit/:aid",
    name: "panel.event.view.abstract.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.edit" */ "@/views/Panel/Event/View/Abstract/Edit.vue"
      ),
  },
  {
    path: "abstract/reviewers",
    name: "panel.event.view.abstract.reviewers",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.reviewers" */ "@/views/Panel/Event/View/Abstract/ReviewersList.vue"
      ),
  },
  {
    path: "abstract/config/settings",
    name: "panel.event.view.abstract.config.settings",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.settings" */ "@/views/Panel/Event/View/Abstract/Config/Settings.vue"
      ),
  },
  {
    path: "abstract/config/topics",
    name: "panel.event.view.abstract.config.topics.list",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.topics.list" */ "@/views/Panel/Event/View/Abstract/Config/Topics/List.vue"
      ),
  },
  {
    path: "abstract/config/topics/add",
    name: "panel.event.view.abstract.config.topics.add",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.topics.add" */ "@/views/Panel/Event/View/Abstract/Config/Topics/Add.vue"
      ),
  },
  {
    path: "abstract/config/topics/edit/:tcid",
    name: "panel.event.view.abstract.config.topics.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.topics.edit" */ "@/views/Panel/Event/View/Abstract/Config/Topics/Edit.vue"
      ),
  },
  {
    path: "abstract/config/symposium",
    name: "panel.event.view.abstract.config.symposium.list",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.symposium.list" */ "@/views/Panel/Event/View/Abstract/Config/Symposium/List.vue"
      ),
  },
  {
    path: "abstract/config/symposium/add",
    name: "panel.event.view.abstract.config.symposium.add",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.symposium.add" */ "@/views/Panel/Event/View/Abstract/Config/Symposium/Add.vue"
      ),
  },
  {
    path: "abstract/config/symposium/edit/:syid",
    name: "panel.event.view.abstract.config.symposium.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.symposium.edit" */ "@/views/Panel/Event/View/Abstract/Config/Symposium/Edit.vue"
      ),
  },
  {
    path: "abstract/config/publication",
    name: "panel.event.view.abstract.config.publication.list",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.publication.list" */ "@/views/Panel/Event/View/Abstract/Config/Publication/List.vue"
      ),
  },
  {
    path: "abstract/config/publication/add",
    name: "panel.event.view.abstract.config.publication.add",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.publication.add" */ "@/views/Panel/Event/View/Abstract/Config/Publication/Add.vue"
      ),
  },
  {
    path: "abstract/config/publication/edit/:puid",
    name: "panel.event.view.abstract.config.publication.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.publication.edit" */ "@/views/Panel/Event/View/Abstract/Config/Publication/Edit.vue"
      ),
  },
  {
    path: "abstract/config/mails",
    name: "panel.event.view.abstract.config.mails",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.mails" */ "@/views/Panel/Event/View/Abstract/Config/Mails.vue"
      ),
  },
  {
    path: "abstract/config/questions",
    name: "panel.event.view.abstract.config.questions",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.config.questions" */ "@/views/Panel/Event/View/Abstract/Config/Questions.vue"
      ),
  },
  {
    path: "abstract/search",
    name: "panel.event.view.abstract.search",
    component: () =>
      import(
        /* webpackChunkName: "event.abstract.search" */ "@/views/Panel/Event/View/Abstract/Search.vue"
      ),
  },
] as RouteConfig[];
