import { RouteConfig } from "vue-router";

export default [
  {
    path: "discount",
    name: "panel.event.view.discount.list",
    component: () =>
      import(
        /* webpackChunkName: "event.discount.list" */ "@/views/Panel/Event/View/EventConfig/Discount/List.vue"
      ),
  },
  {
    path: "discount/add",
    name: "panel.event.view.discount.add",
    component: () =>
      import(
        /* webpackChunkName: "event.discount.add" */ "@/views/Panel/Event/View/EventConfig/Discount/Add.vue"
      ),
  },
  {
    path: "discount/edit/:did",
    name: "panel.event.view.discount.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.discount.edit" */ "@/views/Panel/Event/View/EventConfig/Discount/Edit.vue"
      ),
  },
] as RouteConfig[];
