import { RouteConfig } from "vue-router";

export default [
  {
    path: 'event-program',
    name: 'panel.event.view.eventProgram.view',
    component: () => import(/* webpackChunkName: "event.eventProgram.view" */ '@/views/Panel/Event/View/EventProgram/View.vue')
  },
  {
    path: 'event-program/add',
    name: 'panel.event.view.eventProgram.add',
    component: () => import(/* webpackChunkName: "event.eventProgram.add" */ '@/views/Panel/Event/View/EventProgram/Add.vue')
  },
  {
    path: 'event-program/edit',
    name: 'panel.event.view.eventProgram.edit',
    component: () => import(/* webpackChunkName: "event.eventProgram.edit" */ '@/views/Panel/Event/View/EventProgram/Edit.vue')
  }
] as RouteConfig[]