import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'registrationForm',
    name: 'panel.registrationForm.list',
    component: () => import(/* webpackChunkName: "registrationForm.list" */ '@/views/Panel/GlobalTools/RegistrationForm/List.vue')
  },
  {
    path: 'registrationForm/add',
    name: 'panel.registrationForm.add',
    component: () => import(/* webpackChunkName: "registrationForm.add" */ '@/views/Panel/GlobalTools/RegistrationForm/Add.vue')
  },
  {
    path: 'registrationForm/:ftid',
    name: 'panel.registrationForm.edit',
    component: () => import(/* webpackChunkName: "registrationForm.edit" */ '@/views/Panel/GlobalTools/RegistrationForm/Edit.vue')
  }
] as RouteConfig[]