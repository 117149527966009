import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'degree',
    name: 'panel.degree.list',
    component: () => import(/* webpackChunkName: "degree.list" */ '@/views/Panel/GlobalTools/Degree/List.vue')
  },
  {
    path: 'degree/add',
    name: 'panel.degree.add',
    component: () => import(/* webpackChunkName: "degree.add" */ '@/views/Panel/GlobalTools/Degree/Add.vue')
  }
] as RouteConfig[]