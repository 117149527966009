import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { State as RootState } from '../../state'

export interface Messsage {
  id: string;
  color: string;
  message: string;
}

const namespaced = true

const state = () => ({
  messages: [] as Messsage[],
})

export type SnackbarState = ReturnType<typeof state>

const getters: GetterTree<SnackbarState, RootState> = {
  getMessages: (state) => state.messages,
}

const mutations: MutationTree<SnackbarState> = {
  SET_MESSAGES: (state, value: Messsage[]) => (state.messages = value),
  PUSH_MESSAGE: (state, value: Messsage) => state.messages.push(value),
}

const actions: ActionTree<SnackbarState, RootState> = {
  setMessages: ({ commit }, messages: Messsage[]) =>
    commit('SET_MESSAGES', messages),
  pushMessage: ({ commit }, message: Messsage) =>
    commit('PUSH_MESSAGE', message),
}

export default { namespaced, state, actions, mutations, getters } as Module<SnackbarState, RootState>
