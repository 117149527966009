import { RouteConfig } from "vue-router";

export default [
  {
    path: "website/generate",
    name: "panel.event.view.website.generate",
    component: () =>
      import(
        /* webpackChunkName: "event.website.generate" */ "@/views/Panel/Event/View/Website/Generate.vue"
      ),
  },
  {
    path: "website/info",
    name: "panel.event.view.website.info",
    component: () =>
      import(
        /* webpackChunkName: "event.website.info" */ "@/views/Panel/Event/View/Website/View.vue"
      ),
  },
  {
    path: "website/consent",
    name: "panel.event.view.website.consent",
    component: () =>
      import(
        /* webpackChunkName: "event.website.consent" */ "@/views/Panel/Event/View/Website/Config/Consents.vue"
      ),
  },
  {
    path: "website/partners",
    name: "panel.event.view.website.partner.view",
    component: () =>
      import(
        /* webpackChunkName: "event.website.partner.view" */ "@/views/Panel/Event/View/Website/Config/Partners/List.vue"
      ),
  },
  {
    path: "website/partners/single/add",
    name: "panel.event.website.partner.single.add",
    component: () =>
      import(
        /* webpackChunkName: "event.website.partner.single.add" */ "@/views/Panel/Event/View/Website/Config/Partners/Add.vue"
      ),
  },
  {
    path: "website/partners/single/edit/:spid",
    name: "panel.event.website.partner.single.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.website.partner.single.edit" */ "@/views/Panel/Event/View/Website/Config/Partners/Edit.vue"
      ),
  },
  {
    path: "website/partners/group/add",
    name: "panel.event.website.partner.group.add",
    component: () =>
      import(
        /* webpackChunkName: "event.website.partner.group.add" */ "@/views/Panel/Event/View/Website/Config/Partners/AddGroup.vue"
      ),
  },
  {
    path: "website/partners/group/edit/:pgid",
    name: "panel.event.website.partner.group.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.website.partner.group.edit" */ "@/views/Panel/Event/View/Website/Config/Partners/EditGroup.vue"
      ),
  },
  {
    path: "website/lecturers/config",
    name: "panel.event.website.lecturers.config",
    component: () =>
      import(
        /* webpackChunkName: "event.website.lecturers.config" */ "@/views/Panel/Event/View/Website/Config/Lecturers/Config.vue"
      ),
  },
  {
    path: "website/lecturers/list",
    name: "panel.event.website.lecturers.list",
    component: () =>
      import(
        /* webpackChunkName: "event.website.lecturers.list" */ "@/views/Panel/Event/View/Website/Config/Lecturers/List.vue"
      ),
  },
  {
    path: "website/lecturers/add",
    name: "panel.event.website.lecturers.add",
    component: () =>
      import(
        /* webpackChunkName: "event.website.lecturers.add" */ "@/views/Panel/Event/View/Website/Config/Lecturers/Add.vue"
      ),
  },
  {
    path: "website/lecturers/edit/:lid",
    name: "panel.event.website.lecturers.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.website.lecturers.edit" */ "@/views/Panel/Event/View/Website/Config/Lecturers/Edit.vue"
      ),
  },
  {
    path: "website/participant-zone",
    name: "panel.event.website.participantZone.list",
    component: () =>
      import(
        /* webpackChunkName: "event.website.participantZone.list" */ "@/views/Panel/Event/View/Website/Config/ParticipantZone/List.vue"
      ),
  },
  {
    path: "website/participant-zone/add",
    name: "panel.event.website.participantZone.add",
    component: () =>
      import(
        /* webpackChunkName: "event.website.participantZone.add" */ "@/views/Panel/Event/View/Website/Config/ParticipantZone/Add.vue"
      ),
  },
] as RouteConfig[];
