import { RouteConfig } from "vue-router";

export default [
  {
    path: "participant-group",
    name: "panel.event.view.participantGroup.list",
    component: () =>
      import(
        /* webpackChunkName: "event.participantGroup.list" */ "@/views/Panel/Event/View/EventConfig/ParticipantGroup/List.vue"
      ),
  },
  {
    path: "participant-group/add",
    name: "panel.event.view.participantGroup.add",
    component: () =>
      import(
        /* webpackChunkName: "event.participantGroup.add" */ "@/views/Panel/Event/View/EventConfig/ParticipantGroup/Add.vue"
      ),
  },
  {
    path: "participant-group/edit/:ptgid",
    name: "panel.event.view.participantGroup.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.participantGroup.edit" */ "@/views/Panel/Event/View/EventConfig/ParticipantGroup/Edit.vue"
      ),
  },
] as RouteConfig[];
