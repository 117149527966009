import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'summary-page',
    name: 'panel.summaryPage.list',
    component: () => import(/* webpackChunkName: "summaryPage.list" */ '@/views/Panel/GlobalTools/SummaryPage/List.vue')
  },
  {
    path: 'summary-page/add',
    name: 'panel.summaryPage.add',
    component: () => import(/* webpackChunkName: "summaryPage.add" */ '@/views/Panel/GlobalTools/SummaryPage/Add.vue')
  },
  {
    path: 'summary-page/:id',
    name: 'panel.summaryPage.edit',
    component: () => import(/* webpackChunkName: "summaryPage.edit" */ '@/views/Panel/GlobalTools/SummaryPage/Edit.vue')
  }
] as RouteConfig[]