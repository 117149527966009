import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'lecturer',
    name: 'panel.lecturer.list',
    component: () => import(/* webpackChunkName: "lecturer.list" */ '@/views/Panel/GlobalTools/Lecturer/List.vue')
  },
  {
    path: 'lecturer/add',
    name: 'panel.lecturer.add',
    component: () => import(/* webpackChunkName: "lecturer.add" */ '@/views/Panel/GlobalTools/Lecturer/Add.vue')
  },
  {
    path: 'lecturer/:id',
    name: 'panel.lecturer.edit',
    component: () => import(/* webpackChunkName: "lecturer.edit" */ '@/views/Panel/GlobalTools/Lecturer/Edit.vue')
  }
] as RouteConfig[]