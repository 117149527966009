


























import { computed, defineComponent, reactive } from '@vue/composition-api'
import update from './mixins/update'

export default defineComponent({
  mixins: [update],

  setup(_, { root }) {
    const state = reactive({
      loading: false,
      updateValue: 0
    })

    const messages = computed({
      set: (value: any) => root.$store.commit('snackbar/SET_MESSAGES', value),
      get: () => root.$store.getters['snackbar/getMessages'],
    })

    const downloadUpdate = () => new Promise<void>(resolve => {
      state.loading = true
      state.updateValue = 0

      setTimeout(() => {
        state.updateValue = 25
      }, 250)

      setTimeout(() => {
        state.updateValue = 50
      }, 500)

      setTimeout(() => {
        state.updateValue = 75
      }, 750)

      setTimeout(() => {
        state.updateValue = 100
        resolve()
      }, 1000)
  })

    return { state, messages, downloadUpdate }
  }
})
