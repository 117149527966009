import { RouteConfig } from "vue-router";

export default [
  {
    path: "location-module",
    name: "panel.event.view.locationModule.config",
    component: () =>
      import(
        /* webpackChunkName: "event.locationModule.config" */ "@/views/Panel/Event/View/Modules/LocationModule/Config.vue"
      ),
  },
  {
    path: "location-module/list",
    name: "panel.event.view.locationModule.list",
    component: () =>
      import(
        /* webpackChunkName: "event.locationModule.list" */ "@/views/Panel/Event/View/Modules/LocationModule/List.vue"
      ),
  },
  {
    path: "location-module/add",
    name: "panel.event.view.locationModule.add",
    component: () =>
      import(
        /* webpackChunkName: "event.locationModule.add" */ "@/views/Panel/Event/View/Modules/LocationModule/Add.vue"
      ),
  },
  {
    path: "location-module/edit/:lid",
    name: "panel.event.view.locationModule.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.locationModule.edit" */ "@/views/Panel/Event/View/Modules/LocationModule/Edit.vue"
      ),
  },
] as RouteConfig[];
