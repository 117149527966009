import { Plugin } from 'vuex'
import { State } from './state'

import createPersistedState from 'vuex-persistedstate'

export default [
  createPersistedState({
    key: 'galop_user',
    paths: ['user.data', 'user.auth', 'language.current'],
    storage: window.localStorage
  })
] as Plugin<State>[]