import store from "@/store";
import { RouteConfig } from "vue-router";

import dashboard from "./dashboard";
import location from "./globalTools/location";
import partner from "./globalTools/partner";
import degree from "./globalTools/degree";
import lecturer from "./globalTools/lecturer";
import message from "./globalTools/message";
import registrationForm from "./globalTools/registrationForm";
import user from "./user";
import event from "./event";
import alert from "./alert";
import terms from "./globalTools/terms";
import payments from "./globalTools/payments";
import specialization from "./globalTools/specialization";
import summaryPage from "./globalTools/summaryPage";
import registrationHeader from "./globalTools/registrationHeader";
import siemens from "./siemens";
import companyRegistrationForm from "./globalTools/companyRegistrationForm";
import mailAddress from "./globalTools/mailAddresses";

export default {
  path: "/",
  name: "panel",
  component: () => import(/* webpackChunkName: "panel" */ "@/views/Panel.vue"),
  redirect: { name: "panel.dashboard" },
  beforeEnter(to, from, next) {
    if (!store.getters["user/isLoggedIn"])
      next({ name: "auth", query: { next: to.fullPath } });
    next();
  },
  children: [
    ...dashboard,
    ...location,
    ...partner,
    ...degree,
    ...lecturer,
    ...message,
    ...registrationForm,
    ...user,
    ...event,
    ...alert,
    ...terms,
    ...payments,
    ...specialization,
    ...summaryPage,
    ...registrationHeader,
    ...siemens,
    ...companyRegistrationForm,
    ...mailAddress,
  ],
} as RouteConfig;
