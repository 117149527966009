import Vue from 'vue'

Vue.directive('uppercase', {
  bind (el: any, _, vnode) {
    el.addEventListener('input', (e: any) => {
      e.target.value = e.target.value.toUpperCase()
      vnode?.componentInstance?.$emit('input', e.target.value.toUpperCase())
    })
  }
})
