import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'rodo',
    name: 'panel.rodo.list',
    component: () => import(/* webpackChunkName: "rodo.list" */ '@/views/Panel/GlobalTools/Terms/Rodo/List.vue')
  },
  {
    path: 'rodo/add',
    name: 'panel.rodo.add',
    component: () => import(/* webpackChunkName: "rodo.add" */ '@/views/Panel/GlobalTools/Terms/Rodo/Add.vue')
  },
  {
    path: 'rodo/:id',
    name: 'panel.rodo.edit',
    component: () => import(/* webpackChunkName: "rodo.edit" */ '@/views/Panel/GlobalTools/Terms/Rodo/Edit.vue')
  },
  {
    path: 'termsofservice',
    name: 'panel.termsofservice.list',
    component: () => import(/* webpackChunkName: "termsofservice.list" */ '@/views/Panel/GlobalTools/Terms/TermsOfService/List.vue')
  },
  {
    path: 'termsofservice/add',
    name: 'panel.termsofservice.add',
    component: () => import(/* webpackChunkName: "termsofservice.add" */ '@/views/Panel/GlobalTools/Terms/TermsOfService/Add.vue')
  },
  {
    path: 'termsofservice/:id',
    name: 'panel.termsofservice.edit',
    component: () => import(/* webpackChunkName: "termsofservice.edit" */ '@/views/Panel/GlobalTools/Terms/TermsOfService/Edit.vue')
  },
  {
    path: 'privacy',
    name: 'panel.privacy.list',
    component: () => import(/* webpackChunkName: "privacy.list" */ '@/views/Panel/GlobalTools/Terms/Privacy/List.vue')
  },
  {
    path: 'privacy/add',
    name: 'panel.privacy.add',
    component: () => import(/* webpackChunkName: "privacy.add" */ '@/views/Panel/GlobalTools/Terms/Privacy/Add.vue')
  },
  {
    path: 'privacy/:id',
    name: 'panel.privacy.edit',
    component: () => import(/* webpackChunkName: "privacy.edit" */ '@/views/Panel/GlobalTools/Terms/Privacy/Edit.vue')
  }
] as RouteConfig[]