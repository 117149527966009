import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'paymentMethod',
    name: 'panel.paymentMethod.list',
    component: () => import(/* webpackChunkName: "paymentMethod.list" */ '@/views/Panel/GlobalTools/PaymentMethod/List.vue')
  },
  {
    path: 'paymentMethod/add',
    name: 'panel.paymentMethod.add',
    component: () => import(/* webpackChunkName: "paymentMethod.add" */ '@/views/Panel/GlobalTools/PaymentMethod/Add.vue')
  },
  {
    path: 'paymentMethod/:id',
    name: 'panel.paymentMethod.edit',
    component: () => import(/* webpackChunkName: "paymentMethod.edit" */ '@/views/Panel/GlobalTools/PaymentMethod/Edit.vue')
  }
] as RouteConfig[]