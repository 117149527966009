import { RouteConfig } from "vue-router";
import info from "./info";
import participant from "./participant";
import company from "./company";
import edit from "./edit";
import participantGroup from "./eventConfig/participantGroup";
import registrationModule from "./eventModules/registrationModule";
import report from "./report";
import website from "./website";
import eventProgram from "./eventProgram";
import alertModule from "./eventModules/alertModule";
import autoMessages from "./eventModules/autoMessages";
import abstractModule from "./abstractModule";
import accessControl from "./accessControl";
import budgetModule from "./eventModules/budgetModule";
import discount from "./eventConfig/discount";
import surveyModule from "./eventModules/surveyModule";
import additionalServicesModule from "./eventModules/additionalServicesModule";
import accommodationModule from "./eventModules/accommodationModule";
import additionalConfig from "./eventConfig/additionalConfig";
import locationModule from "./eventModules/locationModule";
import receptionConfig from "./eventModules/receptionConfig";
import posterSession from "./posterSession";
import massPrinting from "./massPrinting";

export default [
  {
    path: "event",
    name: "panel.event.list",
    component: () =>
      import(
        /* webpackChunkName: "event.list" */ "@/views/Panel/Event/List.vue"
      ),
  },
  {
    path: "event/add",
    name: "panel.event.add",
    component: () =>
      import(/* webpackChunkName: "event.add" */ "@/views/Panel/Event/Add.vue"),
  },
  {
    path: "event/:id",
    component: () =>
      import(
        /* webpackChunkName: "event.view" */ "@/views/Panel/Event/View.vue"
      ),
    children: [
      ...info,
      ...edit,
      ...participant,
      ...company,
      ...participantGroup,
      ...registrationModule,
      ...report,
      ...website,
      ...eventProgram,
      ...alertModule,
      ...autoMessages,
      ...abstractModule,
      ...accessControl,
      ...budgetModule,
      ...discount,
      ...surveyModule,
      ...additionalServicesModule,
      ...accommodationModule,
      ...additionalConfig,
      ...locationModule,
      ...receptionConfig,
      ...posterSession,
      ...massPrinting,
    ],
  },
] as RouteConfig[];
