import { RouteConfig } from "vue-router";

export default [
  {
    path: "alert-module",
    name: "panel.event.view.alertModule.list",
    component: () =>
      import(
        /* webpackChunkName: "event.view.alertModule.list" */ "@/views/Panel/Event/View/Modules/AlertModule/List.vue"
      ),
  },
  {
    path: "alert-module/add",
    name: "panel.event.view.alertModule.add",
    component: () =>
      import(
        /* webpackChunkName: "event.view.alertModule.add" */ "@/views/Panel/Event/View/Modules/AlertModule/Add.vue"
      ),
  },
  {
    path: "alert-module/edit/:mtid",
    name: "panel.event.view.alertModule.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.view.alertModule.edit" */ "@/views/Panel/Event/View/Modules/AlertModule/Edit.vue"
      ),
  },
  {
    path: "alert-module/sms",
    name: "panel.event.view.alertModule.sms.list",
    component: () =>
      import(
        /* webpackChunkName: "event.view.alertModule.sms.list" */ "@/views/Panel/Event/View/Modules/AlertModule/SMS/List.vue"
      ),
  },
  {
    path: "alert-module/sms/add",
    name: "panel.event.view.alertModule.sms.add",
    component: () =>
      import(
        /* webpackChunkName: "event.view.alertModule.sms.add" */ "@/views/Panel/Event/View/Modules/AlertModule/SMS/Add.vue"
      ),
  },
  {
    path: "alert-module/sms/edit/:smsid",
    name: "panel.event.view.alertModule.sms.edit",
    component: () =>
      import(
        /* webpackChunkName: "event.view.alertModule.sms.edit" */ "@/views/Panel/Event/View/Modules/AlertModule/SMS/Edit.vue"
      ),
  },
] as RouteConfig[];
