import { RouteConfig } from "vue-router";

export default [
  {
    path: "company-registration-form",
    name: "panel.companyRegistrationForm.list",
    component: () =>
      import(
        /* webpackChunkName: "companyRegistrationForm.list" */ "@/views/Panel/GlobalTools/CompanyRegistrationForm/List.vue"
      ),
  },
  {
    path: "company-registration-form/add",
    name: "panel.companyRegistrationForm.add",
    component: () =>
      import(
        /* webpackChunkName: "companyRegistrationForm.add" */ "@/views/Panel/GlobalTools/CompanyRegistrationForm/Add.vue"
      ),
  },
  {
    path: "company-registration-form/:id",
    name: "panel.companyRegistrationForm.edit",
    component: () =>
      import(
        /* webpackChunkName: "companyRegistrationForm.edit" */ "@/views/Panel/GlobalTools/CompanyRegistrationForm/Edit.vue"
      ),
  },
] as RouteConfig[];
